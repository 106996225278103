<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽布局 -->
    <div class="my-container">

    <!-- 活动推荐  st -->
    <div class="banenr">
    
     
        <img src="/imgs/info.jpg" alt="">
       
    
    </div>
     <!-- 活动推荐  end -->

    <!-- 公司介绍 st -->
    <div class="js">
        <p>北京元跃科技有限公司成立于2022年，旗下拥有MetaLeap、Pixdaddy等多款元宇宙社区产品</p>
        <p>公司经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；人工智能应用软件开发；数据处理和存储支持服务；数字文化创意内容应用服务；数字内容制作服务（不含出版发行）；软件开发；动漫游戏开发；信息技术咨询服务；数字文化创意软件开发；虚拟现实设备制造；玩具制造；游艺用品及室内游艺器材制造；游艺用品及室内游艺器材销售；游艺及娱乐用品销售；计算机软硬件及辅助设备零售；数字文化创意技术装备销售；玩具、动漫及游艺用品销售；玩具销售；文艺创作。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：网络文化经营；第二类增值电信业务；基础电信业务；互联网信息服务；第一类增值电信业务；出版物批发；出版物零售；食品销售；广播电视节目制作经营。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）（不得从事国家和本市产业政策禁止和限制类项目的经营活动。）</p>
    </div>
    <!-- 公司介绍 end -->
    
    <!-- 地图 -->
    <div class="dt">
        <img src="/imgs/dt.png" alt="">
    </div>
    </div>

    <!-- 弹框 -->
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <p>扫码收费：2元</p>
      </template>
    </modal>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
// import navigation from "./../components/navigation";
// import as from "./../components/a";
import Modal from "./../components/Modal.vue";
// import Ma from "./../components/Ma.vue";

export default {
  name: "index",
  data() {
    return {
      tabShow: true,
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "/",
      // 音乐列表
      songList: [],
      // 总页数
      pages: 6,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      drawer: false,
      direction: "ltr",
      my_lyc: [],
      my_title: "",
      imgUrl: "",
      showModal: false,
      m1: [],
      m2: [],
      m3: [],
      m4: [],
      m5: [],
      m6: [],
      m7: [],
      m8: [],
    };
  },
  components: {
    NavHeader,
    Modal,
  },
  mounted() {
    document.title = "元跃科技";
    this.init();
  },
  methods: {
    // 真实支付逻辑
    alipay(data, index) {
      console.log(data);
      var payAmount = 1.0; //支付价格
      var orderName = "音乐下载支付"; //订单名称
      var id = index; //订单名称
      window.open(
        "http://www.cwlo.com.cn/alipay/index.php?payAmount=" +
          payAmount +
          "&orderName=" +
          orderName +
          "&id=" +
          id
      );
    },
    init() {
    // 渲染数据
      this.axios.get("/mock/a.json").then((res) => {
        var data = res.data;
        this.m1 = data.slice(0,9);
        this.m2 = data.slice(9,39);
        this.m3 = data.slice(39,49);
        this.m4 = data.slice(49,58);
        this.m5 = data.slice(58,67);
        this.m6 = data.slice(67,76);
        this.m7 = data.slice(76,106);
        this.m8 = data.slice(106,136);
      });
    },
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },
    // 收费逻辑
    gopay() {
      console.log(this.state);

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("点击登录去购买~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("https://www.jq22.com/jquery/bootstrap-3.3.4.css");
.index {

  .my-container {
    padding-left: 19%;
    box-sizing: border-box;
    // 活动推荐
    .banenr {
      width: 100%;
      height: 300px;
      margin: 30px 0;
      img {
        width: 100%;
        height: 100%;
      }
   
    }

   .js {
    width: 100%;
    p {
        width: 100%;
        line-height: 50px;
        font-size: 14px;
        // color: $colorD;
        color:#333;
        margin: 30px 0;
        &:nth-child(2) {
            // text-indent: 30px;
        }
    }
   }

    .dt {
        width: 100%;
        margin: 40px 0;
        img {
            width: 100%;
        }
    }

  }
}
</style>
