<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽布局 -->
    <div class="my-container">
      <!-- 活动推荐  st -->
      <div class="banenr">
        <h2>活动推荐</h2>
        <div class="b-con">
          <a href="/#/mlist"><img src="/imgs/ct1.jpg" alt="" /></a>
          <a href="/#/ac"><img src="/imgs/ct2.jpg" alt="" /></a>
          <a href="/#/hy"><img src="/imgs/ct3.jpg" alt="" /></a>
        </div>
      </div>
      <!-- 活动推荐  end -->

      <!-- 推荐试听 st -->
      <div class="mc-rec">
        <h2>免费试听</h2>
        <!-- 容器列表 -->
        <div class="rec-con">
          <!-- 循环项 *4 -->
          <div
            class="rec-item"
            v-for="(item, index) in this.my_data"
            :key="index"
            @click="goDetails(item.id)"
          >
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
              <!-- 图标 -->
              <i class="iconfont icon-bofang"></i>
            </div>
            <!-- 歌曲名称 -->
            <p>{{ item.title }}</p>
            <!-- 歌手名称 -->
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- 推荐试听 end -->

      <!-- 试听列表 st -->
      <div class="mc-tj">
        <h2>试听列表</h2>
        <div class="tj-con">
          <!-- 循环项 -->
          <div
            class="tj-item"
            v-for="(item, index) in this.st"
            :key="index"
            @click="goDetails(item.id)"
          >
            <h3><i class="iconfont icon-tuijian1"></i> {{ item.title }}</h3>
            <p>
              <i class="iconfont icon-xiazai1"></i> <span>{{ item.name }}</span>
            </p>
            <div class="bf">
              <i class="iconfont icon-bofang"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 试听列表 end --> 

      

      <!-- 音乐top榜单 st -->
      <div class="mc-top">
        <h2>音乐top榜单</h2>

        <!-- 容器 -->
        <div class="top-con">
          <!-- 循环项 -->
          <div
            class="top-item"
            v-for="(item, index) in this.my_data2"
            :key="index"
            @click="gopay"
          >
            <!-- 歌曲图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
              <i class="iconfont icon-bofang"></i>
            </div>

            <!-- 歌曲名 -->
            <div class="item-title">
              {{ item.title }}
            </div>

            <!-- 歌手 -->
            <div class="item-name">
              {{ item.name }}
            </div>

            <!-- 下载图标 -->
            <div class="item-pay">
              <i class="iconfont icon-xiazai"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 音乐top榜单 end -->

      <!-- banner2 st -->
      <div class="banner2">
        <img src="/imgs/i-banner2.jpg" alt="" />
      </div>
      <!-- banner2 end -->
    </div>

    <!-- 弹框 -->
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <p>扫码收费：2元</p>
      </template>
    </modal>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
// import navigation from "./../components/navigation";
// import as from "./../components/a";
import Modal from "./../components/Modal.vue";
// import Ma from "./../components/Ma.vue";

export default {
  name: "index",
  data() {
    return {
      tabShow: true,
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "/",
      // 音乐列表
      songList: [],
      // 总页数
      pages: 6,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      my_data2: [],
      st: [],
      drawer: false,
      direction: "ltr",
      my_lyc: [],
      my_title: "",
      imgUrl: "",
      showModal: false,
    };
  },
  components: {
    NavHeader,
    Modal,
  },
  mounted() {
    document.title = "元跃科技";
    this.init();
  },
  methods: {
    // 真实支付逻辑
    alipay(data, index) {
      console.log(data);
      var payAmount = 1.0; //支付价格
      var orderName = "音乐下载支付"; //订单名称
      var id = index; //订单名称
      window.open(
        "http://www.cwlo.com.cn/alipay/index.php?payAmount=" +
          payAmount +
          "&orderName=" +
          orderName +
          "&id=" +
          id
      );
    },
    // 获取音乐数据
    init() {
      this.axios.get("/mock/index.json").then((res) => {
        this.my_data2 = res.data;
        this.my_data = res.data.slice(0, 4);
        this.st = res.data.slice(4, 28);
      });
    },
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },
    // 收费逻辑
    gopay() {
      if (this.state == 0) {
        alert("请充值会员后下载~");
        this.$router.push("/hy");
        // this.showModal = true;
        // console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("请先登录~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("https://www.jq22.com/jquery/bootstrap-3.3.4.css");
.index {
  .my-container {
    padding-left: 19%;
    box-sizing: border-box;

    // 活动推荐
    .banenr {
      width: 100%;
      margin-bottom: 20px;
      h2 {
        font-size: 16px;
        font-weight: bold;
        color: $colorB;
        cursor: pointer;
      }
      .b-con {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 365px;
          height: 200px;
          display: block;
          border-radius: 12px;
          background-color: red;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            transform: translateY(-5px);
          }
        }
      }
    }

    // 推荐试听
    .mc-rec {
      width: 100%;
      h2 {
        font-size: 16px;
        font-weight: bold;
        color: $colorB;
        cursor: pointer;
      }

      // 容器列表
      .rec-con {
        width: 100%;
        height: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // 循环项
        .rec-item {
          width: 270px;
          height: 280px;
          cursor: pointer;
          &:hover {
            .item-img {
              i {
                opacity: 1;
              }
            }
          }
          // 图片
          .item-img {
            width: 100%;
            height: 200px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            i {
              font-size: 40px;
              position: absolute;
              right: 15%;
              bottom: -15%;
              color: $colorA;
              font-weight: bold;
              opacity: 0;
              transition: all 0.3s;
            }
          }
          // 歌曲名称
          p {
            font-size: 14px;
            color: $colorC;
            margin: 15px 0;
          }
          // 歌手名称
          span {
            font-size: 14px;
            color: $colorD;
          }
        }
      }
    }

    // 音乐top榜单
    .mc-top {
      width: 100%;
      h2 {
        font-size: 16px;
        font-weight: bold;
        color: $colorB;
        cursor: pointer;
      }
      // 容器
      .top-con {
        width: 100%;
        // height: 600px;
        background: #f1f4fd;
        margin: 30px 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        // 循环项
        .top-item {
          width: 44%;
          height: 75px;
          margin: 15px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: all 0.3s;
          cursor: pointer;
          padding-right: 30px;
          box-sizing: border-box;
          &:hover {
            background: #fff;
            .item-img {
              i {
                opacity: 1;
              }
            }
          }
          // 歌曲图片
          .item-img {
            width: 75px;
            height: 75px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            i {
              font-size: 35px;
              position: absolute;
              left: 26%;
              top: 17%;
              // color: #888;
              color: #000;
              font-weight: bold;
              opacity: 0;
              transition: all 0.3s;
            }
          }

          // 歌曲名
          .item-title {
            font-size: 14px;
            color: $colorB;
            width: 120px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          // 歌手
          .item-name {
            font-size: 14px;
            color: $colorB;
          }

          // 下载图标
          .item-pay {
            i {
              font-size: 24px;
              color: $colorB;
            }
          }
        }
      }
    }

    // 试听列表
    .mc-tj {
      width: 100%;
      h2 {
        font-size: 16px;
        font-weight: bold;
        color: $colorB;
        cursor: pointer;
      }
      // 容器
      .tj-con {
        width: 100%;
        margin: 30px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        // 循环项
        .tj-item {
          width: 30%;
          background: #f1f4fd;
          cursor: pointer;
          margin: 15px 19.5px;
          padding: 10px 5px;
          box-sizing: border-box;
          position: relative;
          &:hover {
            h3 {
              color: #0059a5;
            }
            .bf {
              opacity: 1;
            }
          }
          h3 {
            width: 100%;
            font-size: 16px;
            font-weight: bold;
            i {
              font-size: 18px;
              color: #0059a5;
              font-weight: bold;
              margin-right: 5px;
            }
          }

          p {
            width: 100%;
            margin-top: 5px;
            i {
              font-size: 18px;
              color: #0059a5;
              font-weight: bold;
              margin-right: 15px;
            }

            span {
              font-size: 14px;
              color: #333;
            }
          }
          .bf {
            position: absolute;
            width: 40px;
            height: 40px;
            // background-color: #000;
            top: 7%;
            right: 15%;
            opacity: 0;
            i {
              font-size: 42px;
            }
          }
        }
      }
    }

    // banenr2
    .banner2 {
      width: 100%;
      height: 400px;
      margin: 30px 0;
      background: pink;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
