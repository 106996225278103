<template>
  <div>
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container" style="padding-left: 10%">
      <h2>北京元跃科技有限公司</h2>
      <h3>未成年人使用条款</h3>

      <div class="box-con">
        <p>
          1：若用户是未满18周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用音乐人星球相关服务。
        </p>

        <p>
          2：本公司重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导时正确使用音乐人星球相关服务。
        </p>

        <p>
          3：未成年用户理解如因用户违反法律法规、本协议内容，则用户及用户的监护人应依照法律规定承担因此而导致的一切后果。
        </p>

        <h4>
          本网站符合并积极落实《中华人民共和国未成年人保护法》第七十二条、第七十三条、第七十四条、第七十六条、第七十八条、第八十条等有关规定的措施。未成年人缔结、履行的相关网络交易的特殊规定包括但不限于：
        </h4>

        <p>
          1.
          本网站收集到用户信息并处理未成年人个人信息时，会优先对其身份进行认证和识别，通过电话、客服以及相关技术接口匹配等功能，对未成年人身份进行特殊标注。如遇到不满十四周岁未成年人，则会要求其匹配未成年人的父母或者其他监护人信息并征得同意。
        </p>
        <p>
          2.父母或者其他监护人可以要求网站后台更正、删除未成年人个人信息，我们会在第一时间及时采取措施予以更正、删除。
        </p>
        <p>
          3.
          本网站不提供通过网络发布私密信息的渠道，如发现相关用户（尤其是成年人用户）通过评论、因视频等形式夹带私密信息进行公布，将立即审核并删除该信息。同时，所有在本网站发布的评论和音视频都在后台审核人员审核后，予以公布。
        </p>

        <p>
          4.本网站对未成年人用户会提供防沉迷检测功能，为保护用户使用体验，网络前端不予展示多次弹窗，但识别到用户未成年人信息后，会在其使用1小时后，对账号进行“冷静处理“，限制其使用时间。同时，相关账户在消费过程中，会同步给家长发送验证短信，验证后才可以完成支付。
        </p>

        <p>
          5.本网站仅针对16周岁以上的儿童开放内容发布功能，未满十六周岁的未成年人无法在本网站通过实名认证，也就无法上传和发布相关内容；年满十六周岁会在实名认证后，予以开放相关功能。但对其会进行特殊标签标注，以便审核人员检查相关内容。
        </p>

        <p>
          6.
          当我们发现用户发布、传播可能影响未成年人身心健康的信息且未作显著提示的，会立即提示并作暂时下架处理。我司内容严禁含有危害未成年人身心健康内容的信息的，一旦发现，会立即删除并保存有关记录，并向网信、公安等部门报告。
          当我们发现用户利用其网络服务对未成年人实施违法犯罪行为的，将立即停止向该用户提供网络服务，保存有关记录，并向公安机关报告。
        </p>

        <br />
        <h3>反馈、咨询、投诉渠道：</h3>
        <p>邮箱：duanruoyu@metaleap.com</p>
        <p>电话：010-59490108</p>

        <p>收件地址：北京市朝阳区崔各庄乡马泉营村村北139号院10号楼一层102室</p>
        <p>我们会在第一时间回复您！</p>

        <p style="margin-top: 40px">
          网站进行整改或关停时，我们会进行提供半年进行通知所有支付用户基本情况，按照客户支付费用时间进行对客户的款项退还。
        </p>
        <p>
          如客户本月进行购买会员服务，因运营情况关停的话针对客户进行退还会员注册费用，
          根据客户使用时间进行对费用的评估协商退还客户支付会员会费，
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "元跃科技";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
  padding-top: 60px;
  box-sizing: border-box;
  h2 {
    font-size: 21px;
    color: #333;
    text-align: center;
  }
  h3 {
    font-size: 18px;
    color: #333;
    text-align: center;
    margin: 10px 30px;
  }

  h4 {
    font-size: 18px;
    color: #000;
    text-align: left;
    margin: 15px 0;
    line-height: 35px;
  }
  p {
    margin: 15px 0;
  }
  .box-con {
    font-size: 14px;
    color: #888;
    line-height: 40px;
    h3 {
      text-align: left;
      margin: 10px 0;
    }
    p {
      text-align: left;
    }
  }
}
</style>
