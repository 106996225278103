<template>
  <div class="header">
    <!-- 上层 -->
    <div class="n-s">
      <!-- logo -->
      <div class="n-logo">
        <img src="/imgs/logo.png" alt="" />
        <!-- <h2>元跃科技</h2> -->
      </div>

      <!-- jj -->
      <p>
        最好的音乐是这种音乐，它能够使最优秀.最有教养的人快乐，特别是使那个在品德和修养上最为卓越的一个人快乐。——柏拉图
      </p>

      <!-- 登录注册 -->
      <div class="n-login">
        <span @click="goLogin()" v-show="this.LoggedShow" class="n-login"> 登录</span>
        <span @click="goRegister()" v-show="this.LoggedShow" class="n-zc"> 注册</span>
        <!-- 用户名 -->
        <span class="n-info" v-show="!this.LoggedShow" v-text="this.nv_user.Username"
          ><i class="iconfont icon-denglu"></i
        ></span>

        <!-- 退出登录 -->
        <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i>
          退出登录
        </span>
      </div>
    </div>

    <!-- 导航菜单 -->
    <div class="n-x">
      <ul>
        <li @click="go1()"><a href="javascript:;">网站首页</a></li>
        <li @click="go5()"><a href="javascript:;">音乐列表</a></li>
        <li @click="go2()"><a href="javascript:;">精彩动漫</a></li>
        <li @click="go3()"><a href="javascript:;">关于我们</a></li>
        <li @click="go4()"><a href="javascript:;">尊贵会员</a></li>
        <li @click="go6()"><a href="javascript:;">售后处理</a></li>
        <li @click="go7()"><a href="javascript:;">未成年人保护制度</a></li>
        <!-- <li @click="go7()"><a href="javascript:;">自审制度</a></li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
      // 是否吸顶
      isFixed: false,
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },

    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      location.reload();
      // this.$forceUpdate()
    },

    // 网站首页
    go1() {
      this.$router.push("/index");
    },
    // 精彩动漫
    go2() {
      this.$router.push("/ac");
    },
    // 关于我们
    go3() {
      this.$router.push("/info");
    },
    // 尊贵会员
    go4() {
      this.$router.push("/hy");
    },
    // 音乐列表
    go5() {
      this.$router.push("/mlist");
    },
     // 纠纷处理
    go6() {
      // this.$router.push("/zw2");
      this.$router.push("/ac2");
    },
    // 自审制度
    go7() {
      this.$router.push("/zw3");
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.initHeight, false);
  },
};
</script>
<style lang="scss" scope>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.header {
  position: sticky;
  top: 0;
  z-index: 999;
  // 上层
  .n-s {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    // logo
    .n-logo {
      width: 19%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        font-size: 21px;
        color:#4b5cc4;
        font-weight: bold;
      }
      img {
        width: 200px;
        height: 80px;
      }
    }
    p {
      display: flex;
      height: 100%;
      flex: 1;
      font-size: 12px;
      // color: #999;
      color: #333;
      font-weight: bold;
      align-items: center;
      padding-left: 30px;
      box-sizing: border-box;
    }
    // 登录注册
    .n-login {
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      span {
        cursor: pointer;
      }
      .n-login {
        width: 130px;
        height: 35px;
        line-height: 35px;
        border: 1px solid #ccc;
        color: #4b5cc4;
        text-align: center;
        display: inline-block;
        border-radius: 30px;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          background-color: #4b5cc4;
          color: #fff;
          font-weight: normal;
        }
      }
      .n-zc {
        width: 130px;
        height: 35px;
        line-height: 35px;
        background: #4b5cc4;
        color: #fff;
        text-align: center;
        display: inline-block;
        border-radius: 30px;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.2s;
      }
    }
  }

  // 下层
  .n-x {
    width: 19%;
    position: fixed;
    background: #fff;
    top: 12.5%;
    left: 0;
    z-index: 999;
    padding: 0;
    border: 1px solid #ccc;
    ul {
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;
      li {
        width: 100%;
        text-align: center;
        height: 90px;
        line-height: 100px;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          background-color: #dbe7ed;
          a {
            color: #4b5cc4;
          }
        }
        a {
          font-size: 16px;
          text-decoration: none;
          color: #0059a5;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
