<template>
  <div>
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container" style="padding-left: 10%;">
      <h2>北京元跃科技有限公司</h2>
      <h3>用户纠纷处理</h3>

      <div class="box-con">
        
        <h3>1、用户纠纷处理方式</h3>
        <p>
         （1）如若用户之间发生纠纷且无法协商一致的，可向国家相关行政机关或司法机关寻求解决，包括但不限于向公安机关报案、申请仲裁或向人民法院提起诉讼等。北京元跃科技有限公司将会为实名注册用户提供必要的协助和支持，并根据有关行政机关和司法机关的要求承担一定的举证责任或采取必要措施。
        </p>
        <p>
          （2）用户之间发生纠纷的，也可向北京元跃科技有限公司的客服人员投诉并举证。用户需提供与其账号注册信息一致的个人有效身份证件、必要的国家行政或司法机关文件和其他北京元跃科技有限公司要求提供的相关证据。经北京元跃科技有限公司审核确认后，可以给予必要的协助和支持。
        </p>
        <p>
          （3）用户与北京元跃科技有限公司之间纠纷处理方式
如网站用户对北京元跃科技有限公司的服务有任何异议的，可以向北京元跃科技有限公司的客服人员投诉并举证。查证属实的，北京元跃科技有限公司将立即更正并按照现行法律规定给予用户必要的补偿。

        </p>
        <h3>2、用户纠纷处理流程</h3>
        <p>
          用户产生交易纠纷时及时保留截图以及交易单号等，提交反馈给网站客服或公司联系人员；
        </p>
        <p>
          网站客服和公司联系人员收到反馈和投诉，及时进行核实；
将核实结果实时反馈给用户，北京元跃科技有限公司按照相关法律法规对用户进行问题解决和赔偿；

        </p>
        <p>
         用户对赔偿结果不满意，可以向当地人民法院对北京元跃科技有限公司公司提起诉讼，北京元跃科技有限公司会全力配合人民法院依法工作，提交公司相关业务记录；
        </p>

        <h3>3、用户纠纷处理联系方式</h3>
        <p>
         如有问题可联系北京元跃科技有限公司，公司地址：北京市朝阳区崔各庄乡马泉营村村北139号院10号楼一层102室，联系电话： 010-59490108 
        </p>
        
        <p><br /></p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "元跃科技";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
  padding-top: 60px;
  box-sizing: border-box;
  h2 {
    font-size: 21px;
    color: #333;
    text-align: center;
  }
  h3 {
    font-size: 18px;
    color: #333;
    text-align: center;
    margin: 10px 30px;
  }

  .box-con {
    font-size: 14px;
    color: #888;
    line-height: 40px;
    h3 {
      text-align: left;
      margin: 10px 0;
    }
    p {
      text-align: left;
    }
  }
}
</style>
