<template>
  <div class="nav-footer">
    <div class="w">
      <!-- 导航列表 -->
      <div class="f-list">
        <div class="w">
          <a href="/">网站首页</a> <span>-</span> <a href="/#/ac1">精彩动漫</a>
          <span>-</span> <a href="/#/info">关于我们</a> <span>-</span>
          <a href="/#/hy">尊贵会员</a> 

           <span>-</span> <a href="/#/zw">会员管理制度</a> <span>-</span>
          <a href="/#/zw2">纠纷处理</a> 
          <span>-</span>  <a href="/#/jf">用户协议</a>   <span>-</span>  <a href="/#/zw3">未成年人保护制度</a>
        
        </div>
      </div>
      <div class="f-list">
        <div class="w">
          <a href="https://www.shdf.gov.cn/shdf/channels/740.html">扫黄打非入口</a>
          <span>-</span>
          <a href="https://www.12377.cn/">举报专区</a>
          <span>-</span>
          <!-- <a href="/imgs/zz.jpg" target="_blink">营业执照</a> -->
          <span>-</span>
          <a href="http://cyberpolice.mps.gov.cn/wfjb/">网络110报警服务</a> <span>-</span>
          <a href="https://www.12377.cn/">中国互联网举报中心</a>
        </div>
      </div>
      <div class="f-list">
        <div class="w">
          <a href="https://www.12377.cn/">网络有害信息举报</a>
          <a href="https://www.12377.cn/node_548446.htm">网络举报APP下载</a>
          <span>-</span>
          <a href="https://jbts.mct.gov.cn/">全国文化和旅游市场网上举报投诉</a>

          <span>-</span>
          <!-- <a href="https://www.beian.gov.cn/portal/index.do">经营性网站备案信息</a> -->
          <a href="/imgs/zs.jpg" target="_blink">ICP证书编号：京B2-20222808</a>
        </div>
      </div>

      <!-- 网站信息 -->
      <div class="f-info">
        <!-- 公司名称 -->
        <p>北京元跃科技有限公司</p>
        <p>公司固话：010-59490108 -- 公司邮箱：duanruoyu@metaleap.com </p>
        <p>注册地址：北京市朝阳区崔各庄乡马泉营村村北139号院10号楼一层102室</p>
      
        <p>
           © 2021-2022 myworld.art 版权保护声明 -- 
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
            >网站备案：京ICP备2022016901号-5</a>
          
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {},
  data(){
    return {
      // 网页的基础数据配置
      dataSourc:{}
    }
  },
  mounted() {
     // 查询网站基本配置数据
      this.axios.get("http://8.142.155.68:8099/api/data/sourc/selectAll").then((res) => {
        this.dataSourc = res.data[0]
      });

  }
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-footer {
  width: 100%;
  background-color: #ececec;
  .w {
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    width: 1226px;
    margin: 0 auto;
    // 上层
    .f-list {
      width: 100%;
      display: flex;
      align-items: center;
      height: 30px;
      a {
        // color: #888;
        color: #333;
        // font-weight: bold;
        font-size: 14px;
      }
      span {
        margin: 0 5px;
        color: #333;
        // font-weight: bold;
      }
    }

    // 信息层
    .f-info {
      p {
        // color: #888;
        margin: 10px 0;
         color: #333;
          // font-weight: bold;
          font-size: 14px;
        a {
          // color: #888;
          color: #333;
          // font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
