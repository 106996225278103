<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽布局 -->
    <div class="my-container">
      <!-- 活动推荐  st -->
      <div class="banenr">
        <img src="/imgs/hy-banner.jpg" alt="" />
      </div>
      <!-- 活动推荐  end -->

      <!-- 地图 -->
      <div class="dt">
        <img src="/imgs/hy/1.png" alt="" @click="alipay('{}','1','15.0')">
        <!-- <img src="/imgs/hy/1.png" alt="" @click="gopay" /> -->
        <img src="/imgs/hy/2.png" alt="" />
        <img src="/imgs/hy/3.png" alt="" />
      </div>
    </div>

    <!-- 弹框 -->
    <modal
      title="扫码支付~"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <p style="margin: 15px 0">￥15元=150乐币，充值并兑换</p>
      </template>
    </modal>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
// import navigation from "./../components/navigation";
// import as from "./../components/a";
import Modal from "./../components/Modal.vue";
// import Ma from "./../components/Ma.vue";

export default {
  name: "index",
  data() {
    return {
      tabShow: true,
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "/",
      // 音乐列表
      songList: [],
      // 总页数
      pages: 6,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      drawer: false,
      direction: "ltr",
      my_lyc: [],
      my_title: "",
      imgUrl: "",
      showModal: false,
      m1: [],
      m2: [],
      m3: [],
      m4: [],
      m5: [],
      m6: [],
      m7: [],
      m8: [],
    };
  },
  components: {
    NavHeader,
    Modal,
  },
  mounted() {
    document.title = "元跃科技";
    this.init();
  },
  methods: {
    // 真实支付逻辑
    alipay(data, index, pay) {
      console.log(data);
      var payAmount = pay; //支付价格
      var orderName = "音乐下载支付"; //订单名称
      var id = index; //订单名称

      // 已登录
      if (this.state == 0) {
        window.open(
          "http://www.myworld.art/alipay/index.php?payAmount=" +
            payAmount +
            "&orderName=" +
            orderName +
            "&id=" +
            id
        );
      }
      // 未登录
      if (this.state !== 0) {
        alert("点击登录去购买~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    init() {
      // 渲染数据
      this.axios.get("/mock/a.json").then((res) => {
        var data = res.data;
        this.m1 = data.slice(0, 9);
        this.m2 = data.slice(9, 39);
        this.m3 = data.slice(39, 49);
        this.m4 = data.slice(49, 58);
        this.m5 = data.slice(58, 67);
        this.m6 = data.slice(67, 76);
        this.m7 = data.slice(76, 106);
        this.m8 = data.slice(106, 136);
      });
    },
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },
    // 收费逻辑
    gopay() {
      console.log(this.state);

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("点击登录去购买~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("https://www.jq22.com/jquery/bootstrap-3.3.4.css");
.index {
  .my-container {
    padding-left: 19%;
    box-sizing: border-box;
    // 活动推荐
    .banenr {
      width: 100%;
      height: 300px;
      margin: 30px 0;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .dt {
      width: 100%;
      margin: 40px 0;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
}
</style>
